import { useEffect, useState, useRef } from 'react'
import { downloadJson } from 'Api/downloadJson'

const useRouteJson = ({ polyUrl, elevUrl }) => {
    const loaded = useRef(null)
    const [loading, setLoading] = useState(true)
    const [elev, setElev] = useState([])
    const [poly, setPoly] = useState([])

    const downloadElevJson = () =>
        new Promise(async (resolve) => {
            const res = await downloadJson(elevUrl)
            setElev(res)
            resolve()
        })

    const downloadPolyJson = () =>
        new Promise(async (resolve) => {
            const res = await downloadJson(polyUrl)
            setPoly(res)
            resolve()
        })

    useEffect(() => {
        const preFetchLocationDistances = () => {
            // Otherwise just standard normal post getter
            if (!loaded.current) {
                setLoading(true)
                loaded.current = true

                Promise.all([downloadElevJson(), downloadPolyJson()]).then(
                    () => {
                        setLoading(false)
                    }
                )
            }
        }

        preFetchLocationDistances()
    }, [])

    return { loading, elev, poly }
}

export default useRouteJson
