import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import { linkContent, link } from './style.module.scss'

const FocusLink = ({ children, className, ...other }) => {
    const contentStyle = [linkContent]
    if (className) {
        contentStyle.push(className)
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Link className={link} {...other}>
            <span className={contentStyle.join(' ')} tabIndex="-1">
                {children}
            </span>
        </Link>
    )
}

FocusLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

FocusLink.defaultProps = {
    children: '',
    className: '',
}

export default FocusLink
