const setIntoArray = (arrayItems) => {
    if (!arrayItems || arrayItems.length < 1) {
        return []
    }
    if (Object.prototype.toString.call(arrayItems) === '[object String]') {
        return [arrayItems]
    }
    return arrayItems
}

export default setIntoArray
