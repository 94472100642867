import React from 'react'
import Link from 'Atoms/Link'
import { item, itemList, itemName } from './style.module.scss'

const CategoryList = ({ cats }) => {
    if (cats && cats.length > 0) {
        const allCats = cats.map(({ items, uri }) => {
            if (items && items.length > 0) {
                return items.map(({ slug, name }) => (
                    <li key={slug} className={item}>
                        <Link to={`${uri}/${slug}/`} className={itemName}>
                            {name}
                        </Link>
                    </li>
                ))
            }
            return null
        })
        return <ul className={itemList}>{allCats}</ul>
    }

    // No Categories
    return null
}

export default CategoryList
