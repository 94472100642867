import React from 'react'
import FocusLink from 'Atoms/FocusLink'
import { listItem, crumb, breadcrumbs, listStyle } from './style.module.scss'

const BreadCrumbItem = ({ uri, title, position }) => (
    <li
        className={listItem}
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
    >
        <FocusLink className={crumb} itemProp="item" to={uri}>
            <span itemProp="name">{title}</span>
            <meta content={position} itemProp="position" />
        </FocusLink>
    </li>
)

const Breadcrumbs = ({ crumbs }) => {
    const allCrumbs = [{ title: 'Home', uri: '/' }, ...crumbs]

    // Sort into correct order (in case Gatsby is messing about)
    const orderedCrumbs = allCrumbs.sort((a, b) => {
        const UriA = a.uri.match(/\/.*?/g).length
        const UriB = b.uri.match(/\/.*?/g).length

        let comparison = 0
        if (UriA > UriB) {
            comparison = 1
        } else if (UriA < UriB) {
            comparison = -1
        }
        return comparison
    })

    const list = orderedCrumbs.map((item, index) => (
        <BreadCrumbItem
            key={`breadcrumb-${item.title}`}
            position={index + 1}
            title={item.title}
            uri={item.uri}
        />
    ))

    return (
        <nav className={breadcrumbs}>
            <ol
                className={listStyle}
                itemScope
                itemType="https://schema.org/BreadcrumbList"
            >
                {list}
            </ol>
        </nav>
    )
}

export default Breadcrumbs
