import React from 'react'
import Card from 'Components/Card'
import HeroBanner from 'Components/HeroBanner'
import Pagination from 'Components/Pagination'
import SEO from 'Components/SEO'
import Wysiwyg from 'Components/Wysiwyg'
import CardItem from 'Containers/CardItem'
import CardList from 'Containers/CardList'
import Container from 'Containers/Container'
import Layout from 'Containers/Layout'
import ListingContentContainer from 'Containers/ListingContentContainer'
import slugify from 'Utils/slugify'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'

const RouteTypesTemplate = ({ data, pageContext }) => {
    const {
        allWpRoute: { nodes, pageInfo },
        wpRouteType,
    } = data

    const { index, pageCount, archivePath, postTypeUri } = pageContext

    const { taxonomyFields } = wpRouteType

    const seoImage = getSrc(taxonomyFields?.image?.socialImage)
    const heroImage = getImage(taxonomyFields?.image?.heroBanner) || false

    const rootUri = '/running-routes/'

    const currentPageEnd =
        pageInfo.currentPage === 1 ? '' : `page/${pageInfo.currentPage}`

    const seoTitle = `${wpRouteType.cleanTitle} Running Routes${
        pageInfo.currentPage > 1 ? ' - Page ' + pageInfo.currentPage : ''
    }`

    return (
        <Layout>
            <SEO
                description={wpRouteType.cleanSeoDesc}
                postType="routeList"
                title={seoTitle}
                url={`/${archivePath}`}
                facebookPostImage={seoImage}
                twitterPostImage={seoImage}
            />

            {pageInfo.currentPage === 1 && (
                <>
                    <HeroBanner
                        backImage={heroImage}
                        alt={taxonomyFields?.image?.altText || ''}
                        heading={`${wpRouteType.cleanTitle} Running Routes`}
                    />

                    <ListingContentContainer>
                        <Wysiwyg>{wpRouteType.description}</Wysiwyg>
                    </ListingContentContainer>
                </>
            )}

            <Container listTop={pageInfo.currentPage > 1}>
                <CardList>
                    {nodes.map((card, count) => {
                        // Count to see if the last card is in the middle.
                        // If it is, we need to remove its spacing to stop the
                        // weird flex issue.
                        const removeLastCardFlexSpacing = (count + 1) % 3 === 2

                        const typeName =
                            card.routeTypes?.nodes.map(({ name }) => name) || []

                        return (
                            <CardItem
                                key={slugify(card.uri)}
                                pullLeft={removeLastCardFlexSpacing}
                            >
                                <Card
                                    cats={typeName}
                                    excerpt={card.cleanExcerpt}
                                    headingLevel="h2"
                                    image={getImage(
                                        card.acf_routes.generatedCard?.localFile
                                    )}
                                    imageAlt={`${card.title} run route map card image`}
                                    imageTitle={`${card.title} run route map card image`}
                                    routeLength={card.cleanDistance}
                                    routeElev={card.routeTotalElev}
                                    title={card.title}
                                    uri={card.uri}
                                />
                            </CardItem>
                        )
                    })}
                </CardList>

                <Pagination
                    currentPage={parseInt(pageInfo.currentPage, 10)}
                    numPages={pageInfo.pageCount}
                    prefix={archivePath}
                />
            </Container>
        </Layout>
    )
}

export const routeTypeQuery = graphql`
    query routeTypes($id: Int!, $offset: Int!, $perPage: Int!) {
        wpRouteType(databaseId: { eq: $id }) {
            name
            description
            cleanTitle
            cleanSeoDesc
            taxonomyFields {
                image {
                    altText
                    ...HeroBanner
                    ...SocialImage
                }
            }
        }
        allWpRoute(
            limit: $perPage
            skip: $offset
            sort: { fields: title, order: ASC }
            filter: {
                routeTypes: {
                    nodes: { elemMatch: { databaseId: { eq: $id } } }
                }
            }
        ) {
            nodes {
                title
                cleanTitle
                uri
                excerpt
                content
                cleanExcerpt
                routeDistance
                cleanDistance
                routeTotalElev
                routeTypes {
                    nodes {
                        name
                    }
                }
                acf_routes {
                    generatedCard {
                        ...RouteCardImage
                    }
                }
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
    }
`

export default RouteTypesTemplate
