import React from 'react'
import FocusLink from 'Atoms/FocusLink'
import addTrackingEvent from 'Utils/addTrackingEvent'
import { list, item } from './style.module.scss'

const QuickLinks = ({ links }) => {
    if (links.length > 0) {
        return (
            <nav>
                <ul className={list}>
                    {links.map((link) => (
                        <li key={`amenitity-${link.slug}`} className={item}>
                            <FocusLink
                                onClick={(e) => {
                                    addTrackingEvent({
                                        data: {
                                            name: link.name,
                                        },
                                        event: 'QuickLink',
                                    })
                                }}
                                to={link.slug}
                            >
                                {link.name}
                            </FocusLink>
                        </li>
                    ))}
                </ul>
            </nav>
        )
    }
    return null
}

export default QuickLinks
