import React from 'react'
import CategoryList from 'Components/CategoryList'
import Breadcrumbs from 'Components/Breadcrumbs'
import RouteStats from 'Components/RouteStats'
import RouteImage from 'Components/RouteImage'
import Container from 'Containers/Container'
import PropTypes from 'prop-types'
import {
    hero,
    content,
    contentWrap,
    headingStyle,
    paragraphStyle,
} from './style.module.scss'

const PlaceHero = ({
    crumbs,
    cats,
    paragraph,
    heading,
    distance,
    elevation,
    image,
}) => (
    <div className={hero}>
        <Container>
            <Breadcrumbs crumbs={crumbs} />
            <div className={contentWrap}>
                <div className={content}>
                    <h1 className={headingStyle}>{heading}</h1>
                    {cats && <CategoryList cats={cats} />}
                    <p className={paragraphStyle}>{paragraph}</p>
                    <RouteStats distance={distance} elevation={elevation} />
                </div>
                {image && <RouteImage image={image} />}
            </div>
        </Container>
    </div>
)

PlaceHero.propTypes = {
    heading: PropTypes.string,
    paragraph: PropTypes.string,
    image: PropTypes.shape({ src: PropTypes.string }),
}

PlaceHero.defaultProps = {
    heading: '',
    paragraph: '',
    image: false,
}

export default PlaceHero
