function chunk(array, size) {
    if (!array) return []
    const firstChunk = array.slice(0, size) // create the first chunk of the given array
    if (!firstChunk.length) {
        return array // this is the base case to terminal the recursive
    }
    return [firstChunk].concat(chunk(array.slice(size, array.length), size))
}

const getPagesChunk = (array, page) => {
    const arrayIndex = page - 1

    const chunkedPages = chunk(array, 12)

    const pageCount = chunkedPages.length

    // If the request is asked for a page that
    // does not exist. Return false.
    if (arrayIndex > pageCount) {
        return false
    }

    return chunkedPages[arrayIndex]
}

export default getPagesChunk
