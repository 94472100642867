import React from 'react'
import RootButton from 'Atoms/Button'
import PropTypes from 'prop-types'

const Button = ({ customLink, customUrlSet, link, children }) => {
    let uri = link?.uri
    if (customUrlSet) {
        uri = customLink
    }
    if (!uri || !children) {
        return null
    }
    return <RootButton to={uri}>{children}</RootButton>
}

Button.propTypes = {
    children: PropTypes.string,
    customLink: PropTypes.string,
    customUrlSet: PropTypes.bool,
    link: PropTypes.shape({ uri: PropTypes.string }),
}

Button.defaultProps = {
    children: '',
    customLink: '',
    customUrlSet: false,
    link: {},
}

export default Button
