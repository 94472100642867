import React from 'react'
import { statItem, stats } from './style.module.scss'

const RouteStats = ({ distance, elevation }) => (
    <div className={stats}>
        {distance && (
            <p className={statItem}>
                <span>Distance:</span>
                {distance}km
            </p>
        )}
        {elevation && (
            <p className={statItem}>
                <span>Elevation gain:</span>
                {elevation}m
            </p>
        )}
    </div>
)

export default RouteStats
