import React, { useEffect, useState, useRef } from 'react'
import getLatLngFromPostcode from 'Api/getLatLngFromPostcode'
import InputBar from 'Components/InputBar'
import PurpleCurveImage from 'Components/PurpleCurveImage'
import Container from 'Containers/Container'
import { useLocation } from '@reach/router'
import addTrackingEvent from 'Utils/addTrackingEvent'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {
    wrap,
    contentWrap,
    layout,
    content,
    imageStyle,
    headingStyle,
    textButton,
    breakHead,
} from './style.module.scss'

const SlimSearch = ({ scrollPointRef, rootUri, searchId, searchLabel }) => {
    const location = useLocation()

    const inputRef = useRef(null)

    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const [geoError, setGeoError] = useState('')

    const queryParams =
        queryString.parse(location.search, { arrayFormat: 'comma' }) || {}

    const data = useStaticQuery(graphql`
        query SlimBanner {
            file(relativePath: { eq: "hero.jpg" }) {
                id
                root
                relativePath
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
        }
    `)

    useEffect(() => {
        // If it is a new page and there is a scroll state, or there is
        // a scroll state.
        if (location?.state?.scroll) {
            scrollPointRef.current.scrollIntoView({ behavior: 'smooth' })
            // Clean up location state now we have used it.
            window.history.replaceState(null, '')
        }
    }, [queryParams?.page, queryParams?.lat, location?.state?.key])

    const handleOnChange = (e) => {
        setValue(e.target.value)

        // reset errors
        setError('')
        setGeoError('')
    }

    const handleOnSubmit = async () => {
        // If no value, do nothing
        if (!value || value.length < 4) {
            setError('Please enter a full postcode.')
            inputRef.current.focus()
            return
        }

        const res = await getLatLngFromPostcode(value)

        if (res?.status !== 'success') {
            setError(res.message)
            inputRef.current.focus()
        }

        if (res?.status === 'success') {
            addTrackingEvent({
                data: {
                    lat: res.location[1],
                    lng: res.location[0],
                    searchType: 'manual',
                    searchValue: value,
                },
                event: 'LocationSearch',
            })

            // Strip out pages.
            const paramsToEdit = { ...queryParams }

            delete paramsToEdit.page

            paramsToEdit.lat = res.location[1]
            paramsToEdit.lng = res.location[0]

            navigate(
                `${rootUri}?${queryString.stringify(paramsToEdit, {
                    arrayFormat: 'comma',
                })}`,
                { state: { scroll: true } }
            )
        }
    }

    const handleGeoClick = () => {
        setGeoError('')
        if (!navigator.geolocation) {
            setGeoError('Geolocation is not supported by your browser.')
        } else {
            navigator.geolocation.getCurrentPosition(
                (geo) => {
                    // Success
                    addTrackingEvent({
                        data: {
                            lat: geo.coords.latitude,
                            lng: geo.coords.longitude,
                            searchType: 'geo',
                        },
                        event: 'LocationSearch',
                    })

                    // Strip out pages.
                    const paramsToEdit = { ...queryParams }

                    delete paramsToEdit.page

                    paramsToEdit.lat = geo.coords.latitude
                    paramsToEdit.lng = geo.coords.longitude

                    navigate(
                        `${rootUri}?${queryString.stringify(paramsToEdit, {
                            arrayFormat: 'comma',
                        })}`,
                        { state: { scroll: true } }
                    )
                },
                () => {
                    // Error
                    setGeoError('Unable to find your location.')
                }
            )
        }
    }

    return (
        <div className={wrap}>
            <GatsbyImage
                alt=""
                image={getImage(data.file)}
                loading="eager"
                className={imageStyle}
                style={{ position: 'absolute' }}
            />
            <div className={contentWrap}>
                <Container>
                    <div className={layout}>
                        <div className={content}>
                            <h1 className={headingStyle}>
                                Where Do You{' '}
                                <span className={breakHead}>Want To Run?</span>
                            </h1>

                            <InputBar
                                ref={inputRef}
                                dark
                                error={error}
                                id={searchId}
                                label={searchLabel}
                                onChange={handleOnChange}
                                onSubmit={handleOnSubmit}
                                submitText="Go"
                                value={value}
                            />
                            <button
                                className={textButton}
                                onClick={() => handleGeoClick()}
                                type="button"
                            >
                                <span tabIndex="-1">Use Current Location</span>
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

SlimSearch.propTypes = {
    heading: PropTypes.string,
    paragraph: PropTypes.string,
    rootUri: PropTypes.string,
}

SlimSearch.defaultProps = {
    heading: '',
    paragraph: '',
    rootUri: '',
}

export default SlimSearch
