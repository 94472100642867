// extracted by mini-css-extract-plugin
export var card = "style-module--card--uiG0x";
export var cardInner = "style-module--cardInner--fB4YO";
export var catStyle = "style-module--catStyle--6OdmK";
export var catsStyle = "style-module--catsStyle--LR0cg";
export var contentWrap = "style-module--contentWrap--IU4gF";
export var distanceStyle = "style-module--distanceStyle--FvI1Y";
export var excerptStyle = "style-module--excerptStyle--QD5ea";
export var imageStyle = "style-module--imageStyle--PCQU-";
export var imageWrap = "style-module--imageWrap--cRSHu";
export var spin = "style-module--spin--oIcZ8";
export var statItem = "style-module--statItem--7g7qO";
export var stats = "style-module--stats--z3X0e";
export var titleStyle = "style-module--titleStyle--x7cuu";