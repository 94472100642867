import React from 'react'
import Chart from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import {
    wrap,
    swatches,
    swatchItem,
    inclineSwatch,
    declineSwatch,
} from './style.module.scss'

const ElevationChart = ({ elev, incline, decline }) => {
    if (elev) {
        const graphData = []
        const labels = []

        const {
            firstIndex: inclineFirstIndex,
            lastIndex: inclineLastIndex,
            elev: inclineElev,
            dist: inclineDist,
        } = JSON.parse(incline)
        const {
            firstIndex: declineFirstIndex,
            lastIndex: declineLastIndex,
            elev: declineElev,
            dist: declineDist,
        } = JSON.parse(decline)

        elev.forEach((item, i) => {
            labels.push({ y: item[0] })

            const isIncline =
                item[0] >= inclineFirstIndex && item[0] <= inclineLastIndex
            const isDecline =
                item[0] >= declineFirstIndex && item[0] <= declineLastIndex

            graphData.push({ x: i, y: item[1], isIncline, isDecline })
        })

        return (
            <div className={wrap}>
                <p>Route Elevation</p>
                <Line
                    options={{
                        responsive: true,
                        elements: {
                            line: {
                                borderColor: '#243258',
                                tension: 0.3,
                            },
                        },
                        scales: {
                            xAxis: {
                                display: false,
                            },
                            y: {
                                ticks: {
                                    callback: function (val) {
                                        return `${val} m`
                                    },
                                },
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: { enabled: false },
                            legend: {
                                display: false,
                            },
                        },
                    }}
                    data={{
                        labels,
                        datasets: [
                            {
                                data: graphData,
                                segment: {
                                    borderColor: (ctx) => {
                                        if (
                                            ctx.p0.raw.isIncline &&
                                            ctx.p1.raw.isIncline
                                        ) {
                                            return '#e71212'
                                        } else if (
                                            ctx.p0.raw.isDecline &&
                                            ctx.p1.raw.isDecline
                                        ) {
                                            return '#26c933'
                                        } else {
                                            return '#243258'
                                        }
                                    },
                                },
                            },
                        ],
                    }}
                />
                <div className={swatches}>
                    {incline && inclineElev > 0 && (
                        <div className={swatchItem}>
                            <span className={inclineSwatch} />
                            <p>{`Longest incline - ${inclineElev}m gain over ${inclineDist}m`}</p>
                        </div>
                    )}
                    {decline && declineElev > 0 && (
                        <div className={swatchItem}>
                            <span className={declineSwatch} />
                            <p>{`Longest decline - ${declineElev}m drop over ${declineDist}m`}</p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return null
}

export default ElevationChart
