import React from 'react'
import CustomTag from 'Atoms/CustomTag'
import Link from 'Atoms/Link'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import RouteStats from 'Components/RouteStats'
import {
    catsStyle,
    catStyle,
    card,
    contentWrap,
    titleStyle,
    cardInner,
    imageStyle,
    imageWrap,
    excerptStyle,
    distanceStyle,
    stats,
    statItem,
} from './style.module.scss'

const Card = ({
    cats,
    distance,
    routeElev,
    routeLength,
    excerpt,
    image,
    imageAlt,
    imageTitle,
    tag,
    title,
    uri,
    headingLevel,
}) => {
    const cardStyle = [card]

    const milePlural = distance === 1 ? 'mile' : 'miles'

    return (
        <Link className={cardStyle.join(' ')} to={uri}>
            <div className={cardInner} tabIndex="-1">
                <div className={imageWrap}>
                    <GatsbyImage
                        alt={imageAlt}
                        className={imageStyle}
                        image={image}
                        title={imageTitle}
                        style={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                        }}
                    />

                    {cats.length > 0 && (
                        <ul className={catsStyle}>
                            {cats.map((cat) => (
                                <li className={catStyle} key={cat}>
                                    {cat}
                                </li>
                            ))}
                        </ul>
                    )}

                    {!isNaN(distance) && (
                        <p className={distanceStyle}>
                            {`${distance} ${milePlural} away`}
                        </p>
                    )}
                </div>

                <div className={contentWrap}>
                    <CustomTag className={titleStyle} tag={headingLevel}>
                        {title}
                    </CustomTag>

                    {(routeLength || routeElev) && (
                        <div className={stats}>
                            {routeLength && (
                                <p className={statItem}>
                                    <span>Distance</span>
                                    {routeLength}km
                                </p>
                            )}
                            {routeElev && (
                                <p className={statItem}>
                                    <span>Elevation gain</span>
                                    {routeElev}m
                                </p>
                            )}
                        </div>
                    )}

                    <p className={excerptStyle}>{excerpt}</p>
                </div>
            </div>
        </Link>
    )
}

Card.propTypes = {
    cats: PropTypes.arrayOf(PropTypes.string),
    distance: PropTypes.number,
    routeElev: PropTypes.number,
    routeLength: PropTypes.number,
    excerpt: PropTypes.string,
    headingLevel: PropTypes.string,
    image: PropTypes.shape({}),
    imageAlt: PropTypes.string,
    imageTitle: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
}

Card.defaultProps = {
    cats: [],
    distance: undefined,
    routeElev: undefined,
    routeLength: undefined,
    excerpt: '',
    headingLevel: 'h3',
    image: null,
    imageAlt: '',
    imageTitle: '',
    tag: '',
}

export default Card
