import React from 'react'
import Form from 'Acf/Form'
import HeroBanner from 'Acf/HeroBanner'
import ImageContentSquare from 'Acf/ImageContentSquare'
import CardPanel from 'Acf/CardPanel'
import Wysiwyg from 'Acf/Wysiwyg'
import { getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const panelImage = (object, num) => ({
    alt: object[`image${num}`]?.altText,
    image: getImage(object[`image${num}`]?.localFile),
    title: object[`image${num}`]?.title,
})

const AcfBuilder = ({ data }) => {
    let acfComponents = null
    // If we have ACF fields to render...
    if (data && data.length > 0) {
        // Loop over each ACF field and add to const.
        let count = 0
        acfComponents = data.map((acf) => {
            count += 1

            // As the post type has already been checked before this component,
            // we only care about what the ACF field is. So lets remove the
            // post type label from field.

            /* eslint-disable no-underscore-dangle */
            const acfFieldName = acf.__typename.split('_').slice(3).join('_')

            const itemKey = `acf-field-${count}-${acfFieldName}`

            if (acfFieldName === 'CardPanel') {
                return (
                    <CardPanel
                        button={acf?.button}
                        cards={acf?.cards}
                        content={acf?.content}
                        heading={acf?.heading}
                        key={itemKey}
                        sectionId={acf.sectionId}
                    />
                )
            }

            if (acfFieldName === 'Form') {
                return (
                    <Form
                        key={itemKey}
                        sectionId={acf.sectionId}
                        form={acf.form}
                        trackingName={acf.trackingName}
                    />
                )
            }

            if (acfFieldName === 'HeroBanner') {
                return (
                    <HeroBanner
                        key={itemKey}
                        button={acf?.button}
                        heading={acf?.heading}
                        paragraph={acf?.subheading}
                    />
                )
            }

            if (acfFieldName === 'ImageContentSquare') {
                return (
                    <ImageContentSquare
                        key={itemKey}
                        sectionId={acf.sectionId}
                        button={acf?.button}
                        content={acf?.content}
                        contentPosition={acf?.contentPosition}
                        heading={acf?.heading}
                        headingTag={acf?.headingTag}
                        image={getImage(acf?.image?.localFile)}
                        imageAlt={acf?.image?.imageAlt}
                        imageTitle={acf?.image?.title}
                    />
                )
            }

            if (acfFieldName === 'Wysiwyg') {
                return (
                    <Wysiwyg
                        key={itemKey}
                        sectionId={acf.sectionId}
                        content={acf?.content}
                        isFirst={count === 1}
                    />
                )
            }

            return null

            // Field has not yet been created, so return false.
            // return false
        })
    }
    return acfComponents
}

AcfBuilder.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

AcfBuilder.defaultProps = {
    data: {},
}

export default AcfBuilder
