import queryString from 'query-string'

const getLatLngFromPostcode = async (postcode) => {
    // Get all the pieces for the MapBox API and build the URL
    const apiCall = `https://api.mapbox.com/geocoding/v5/mapbox.places/`
    const address = `${encodeURI(postcode)}.json?`
    const apiQuery = {
        access_token: process.env.GATSBY_MAPBOX_TOKEN,
        country: 'gb',
        limit: 1,
        types: 'address,postcode',
    }

    const fullApiUrl = apiCall + address + queryString.stringify(apiQuery)

    try {
        // Get Lat Lng from Mapbox.
        const response = await fetch(fullApiUrl).then((r) => r.json())

        if (response?.features?.length > 0) {
            return {
                location: response.features[0].center,
                status: 'success',
            }
        }
        return {
            message: `Postcode looks incorrect. Please try again.`,
            status: 'noneFound',
        }
    } catch (error) {
        return {
            message: `There was a problem our end, please try again.`,
            status: 'error',
        }
    }
}

export default getLatLngFromPostcode
