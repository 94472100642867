import React from 'react'
import Button from 'Acf/Button'
import Title from 'Atoms/Title'
import Container from 'Containers/Container'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as style from './style.module.scss'

const ImageContentSquare = ({
    sectionId,
    button,
    heading,
    headingTag,
    content,
    contentPosition,
    image,
    imageAlt,
    imageTitle,
}) => {
    const wrapperStyling = [style.wrapper, style[contentPosition]]

    return (
        <div id={sectionId} className={style.ImageContentSquare}>
            <Container>
                <div className={wrapperStyling.join(' ')}>
                    <div className={style.side}>
                        <GatsbyImage
                            alt={imageAlt}
                            className={style.image}
                            image={image}
                            title={imageTitle}
                        />
                    </div>
                    <div className={style.side}>
                        {heading && (
                            <Title heading={headingTag}>{heading}</Title>
                        )}

                        {content && (
                            <p
                                className={style.content}
                                /* eslint-disable react/no-danger */
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}

                        <Button
                            customLink={button?.customLink}
                            customUrlSet={button?.customUrlSet}
                            link={button?.link}
                        >
                            {button?.text}
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

ImageContentSquare.propTypes = {
    sectionId: PropTypes.string,
    button: PropTypes.shape({
        customLink: PropTypes.string,
        customUrlSet: PropTypes.bool,
    }),
    content: PropTypes.string,
    contentPosition: PropTypes.string,
    heading: PropTypes.string,
    headingTag: PropTypes.string,
    image: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.number,
        ])
    ),
    imageAlt: PropTypes.string,
    imageTitle: PropTypes.string,
}

ImageContentSquare.defaultProps = {
    sectionId: '',
    button: {},
    content: '',
    contentPosition: 'left',
    heading: '',
    headingTag: 'h2',
    image: null,
    imageAlt: '',
    imageTitle: '',
}

export default ImageContentSquare

export const AcfImageContentSquareImage = graphql`
    fragment AcfImageContentSquareImage on WpMediaItem {
        altText
        title
        localFile {
            childImageSharp {
                gatsbyImageData(width: 480, height: 590)
            }
        }
    }
`

export const PageAcfImageContentSquare = graphql`
    fragment PageAcfImageContentSquare on WpPage_AcfFlexibleFields_FlexibleFields_ImageContentSquare {
        sectionId
        heading
        headingTag
        content
        contentPosition
        image {
            ...AcfImageContentSquareImage
        }
        button {
            customLink
            customUrlSet
            fieldGroupName
            link {
                ... on WpArticle {
                    uri
                }
                ... on WpPage {
                    uri
                }
                ... on WpRoute {
                    uri
                }
            }
            text
        }
    }
`
