import React, { useRef, useState, useEffect, Suspense } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import addTrackingEvent from 'Utils/addTrackingEvent'
import isObjectEmpty from 'Utils/isObjectEmpty'
import setIntoArray from 'Utils/setIntoArray'

const Panel = React.lazy(() => import('./Panel'))

import {
    counter,
    scroll,
    title,
    filter,
    filterButton,
    listStyle,
    itemStyle,
    itemDownArrow,
    leftEdge,
    rightEdge,
    scrollClip,
} from './style.module.scss'

const Filter = ({ filters, open, setOpen, pageUri }) => {
    const isBrowser = typeof window !== 'undefined'

    // Handle panel rendering and lazy component.
    const loadedOnce = useRef(false)
    if (open && loadedOnce.current === false) {
        loadedOnce.current = true
    }

    const location = useLocation()

    // Convert URL query into usable object with arrays.
    const selectedItems =
        queryString.parse(location.search, { arrayFormat: 'comma' }) || {}

    // Set pre-existing URL query to filter state.
    const [queryObject, setQueryObject] = useState(selectedItems)

    useEffect(() => {
        setQueryObject(selectedItems)
    }, [Object.keys(selectedItems).length])

    const returnToUrlQueryState = () => {
        setQueryObject(selectedItems)
    }

    // Update query state with new items
    const updateUrlQuery = ({ name, items }) => {
        setQueryObject({ ...queryObject, [name]: items })
    }

    const resetUrlQuery = () => {
        setQueryObject({})
    }

    const setBrowserQueryUrl = (newObject) => {
        const newUrl = `/${pageUri}/?${queryString.stringify(newObject, {
            arrayFormat: 'comma',
        })}`

        if (location.search !== newUrl) {
            navigate(newUrl, { state: { scroll: true } })
        }
    }

    // On filter click, parse filter state to URL.
    const handleFilterClick = () => {
        if (isObjectEmpty(queryObject)) {
            navigate(`/${pageUri}/`)
        } else {
            setBrowserQueryUrl(queryObject)
        }
        setOpen('')
    }

    // Find the correct data for the selected panel.
    const activeFilterData = filters.find((item) => item.slug === open)

    return (
        <>
            <div className={filter}>
                <div className={leftEdge} />
                <div className={scrollClip}>
                    <ul className={listStyle}>
                        {filters.map(({ id, name, slug, items, type }) => {
                            // If no items ignore.
                            if (!items) {
                                return null
                            }

                            // If no items for check box ignore.
                            if (type === 'check' && items.length < 1) {
                                return null
                            }

                            const selectedCount =
                                setIntoArray(selectedItems?.[slug])?.length ||
                                false

                            return (
                                <li className={itemStyle} key={`${slug}-${id}`}>
                                    <button
                                        className={filterButton}
                                        onClick={() => {
                                            addTrackingEvent({
                                                data: {
                                                    name: slug,
                                                },
                                                event: 'FilterOpen',
                                            })
                                            setOpen(slug)
                                        }}
                                    >
                                        {name}
                                        {selectedCount && (
                                            <span
                                                className={counter}
                                                key={`count-${slug}`}
                                            >
                                                ({selectedCount})
                                            </span>
                                        )}
                                        <div
                                            className={itemDownArrow}
                                            key={`arrow-${slug}`}
                                        />
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={rightEdge} />
            </div>

            {loadedOnce && isBrowser && (
                <Suspense fallback={<div />}>
                    <Panel
                        open={open}
                        type={activeFilterData?.type}
                        setOpen={setOpen}
                        title={activeFilterData?.name}
                        slug={activeFilterData?.slug}
                        items={activeFilterData?.items}
                        multiple={activeFilterData?.multiple}
                        resetToCurrent={returnToUrlQueryState}
                        updateUrlQuery={updateUrlQuery}
                        resetUrlQuery={resetUrlQuery}
                        onFilterClick={handleFilterClick}
                        selected={queryObject}
                    />
                </Suspense>
            )}
        </>
    )
}

Filter.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
}

Filter.defaultProps = {
    filters: [],
}

export default Filter
