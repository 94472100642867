import React from 'react'
import { wrap, context, date } from './style.module.scss'

const LastModifiedDate = ({ userDate, schemaDate }) => (
    <div className={wrap}>
        <span className={context}>Last Updated: </span>
        <time className={date} dateTime={schemaDate} itemProp="dateModified">
            {userDate}
        </time>
    </div>
)

export default LastModifiedDate
