import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { imageStyle } from './style.module.scss'

const ArticleImage = ({ alt, image, title }) => {
    if (!image) {
        return null
    }
    return (
        <GatsbyImage
            alt={alt}
            className={imageStyle}
            image={image}
            loading="eager"
            title={title}
        />
    )
}

export default ArticleImage
