import React from 'react'

import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'

import Title from 'Atoms/Title'
import ArticleImage from 'Components/ArticleImage'
import Breadcrumbs from 'Components/Breadcrumbs'
import CategoryList from 'Components/CategoryList'
import TagList from 'Components/TagList'
import LastModifiedDate from 'Components/LastModifiedDate'
import SEO from 'Components/SEO'
import CardPanel from 'Components/CardPanel'
import Wysiwyg from 'Components/Wysiwyg'
import ArticleContainer from 'Containers/ArticleContainer'
import Layout from 'Containers/Layout'

const ArticleTemplate = (props) => {
    const {
        data: {
            relatedArticles,
            wpArticle: {
                author: { node: author },
                content,
                date,
                cleanTitle,
                cleanSeoDesc,
                modified,
                modifiedForUser,
                modifiedForSchema,
                featuredImage,
                categories,
                tags,
                seo,
            },
        },
        uri,
    } = props

    const seoImage = getSrc(featuredImage?.node?.socialImage)
    const articleImage = getImage(featuredImage?.node?.articleBanner)

    const postTypeUri = '/running-articles/'

    const breadcrumbs = [
        {
            title: 'Articles',
            uri: postTypeUri,
        },
        { title: cleanTitle, uri },
    ]

    return (
        <Layout>
            <SEO
                author={author.name}
                dateModified={modified}
                datePublished={date}
                description={cleanSeoDesc}
                facebookPostImage={seoImage}
                postType="article"
                title={cleanTitle}
                twitterPostImage={seoImage}
                url={uri}
                yoastTitle={seo.title}
            />

            <ArticleContainer>
                <article>
                    <Title large>{cleanTitle}</Title>

                    <Breadcrumbs crumbs={breadcrumbs} />

                    <CategoryList
                        cats={[
                            {
                                uri: '/running-articles/category',
                                items: categories.nodes,
                            },
                        ]}
                    />

                    <LastModifiedDate
                        schemaDate={modifiedForSchema}
                        userDate={modifiedForUser}
                    />

                    <ArticleImage
                        alt={featuredImage?.node?.altText}
                        image={articleImage}
                        title={featuredImage?.node?.title}
                    />

                    <Wysiwyg>{content}</Wysiwyg>
                    <TagList tags={tags.nodes} />
                </article>
            </ArticleContainer>

            <CardPanel
                cards={relatedArticles.nodes}
                uri="running-articles"
                content=""
                heading="More running articles"
            />
        </Layout>
    )
}

export const postQuery = graphql`
    query article($id: Int!, $related: [Int!]) {
        wpArticle(databaseId: { eq: $id }) {
            author {
                node {
                    avatar {
                        url
                    }
                    name
                }
            }
            modifiedForUser: modified(formatString: "DD/MM/YYYY")
            modifiedForSchema: modified(formatString: "YYYY-MM-DD, HH:mm:ss")
            modified
            date
            title
            cleanTitle
            content
            databaseId
            excerpt
            cleanSeoDesc
            featuredImage {
                node {
                    altText
                    title
                    ...ArticleBanner
                    ...SocialImage
                }
            }
            categories {
                nodes {
                    slug
                    name
                }
            }
            tags {
                nodes {
                    slug
                    name
                }
            }
            seo {
                ...SEO
            }
        }
        relatedArticles: allWpArticle(
            filter: { databaseId: { in: $related } }
        ) {
            nodes {
                title
                cleanTitle
                nodeType
                content
                slug
                excerpt
                cleanExcerpt
                featuredImage {
                    node {
                        altText
                        title
                        ...CardImage
                    }
                }
                seo {
                    ...SEO
                }
            }
        }
    }
`

export default ArticleTemplate
