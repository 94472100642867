import React from 'react'
// import AdSense from 'react-adsense'
import { adBlock, theAd } from './style.module.scss'

const AdBlock = () => (
    <div className={adBlock}>
        {/* <p className={title}>
            A loverly little ad break! <span>Continue reading below.</span>
        </p> */}
        <div className={theAd}>
            {/* <AdSense.Google
                client="ca-pub-5302069223665415"
                slot="1111528544"
                style={{ display: 'block' }}
                layout="in-article"
                format="fluid"
            /> */}
            <script
                type="text/javascript"
                src="https://udbaa.com/bnr.php?section=General&pub=997685&format=468x60&ga=g"
            ></script>
            <noscript>
                <a href="https://yllix.com/publishers/997685" target="_blank">
                    <img
                        src="//ylx-aff.advertica-cdn.com/pub/468x60.png"
                        // style="border:none;margin:0;padding:0;vertical-align:baseline;"
                        alt="ylliX - Online Advertising Network"
                    />
                </a>
            </noscript>
        </div>
    </div>
)

export default AdBlock
