import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SearchIcon from 'Assets/svgs/search.svg'
import {
    barStyle,
    fieldStyle,
    wrapStyle,
    wrapStyleDark,
    inputStyle,
    labelStyle,
    searchText,
    errorStyle,
    searchIcon,
    buttonStyle,
} from './style.module.scss'

const InputBar = React.forwardRef(
    ({ error, id, label, onChange, onSubmit, submitText, value }, ref) => {
        const [focused, setFocused] = useState(false)

        return (
            <form
                className={barStyle}
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit()
                }}
            >
                <div
                    className={fieldStyle}
                    data-active-attr={value ? true : null}
                    data-focus-attr={focused}
                    data-error-attr={error ? true : null}
                >
                    <div className={wrapStyle}>
                        <input
                            ref={ref}
                            className={inputStyle}
                            defaultValue={value}
                            id={id}
                            name={id}
                            onChange={onChange}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            type="text"
                        />
                        <label className={labelStyle} htmlFor={id}>
                            {label}
                        </label>
                    </div>
                    <button className={buttonStyle} type="submit">
                        <span tabIndex="-1">
                            <SearchIcon className={searchIcon} />
                            <span className={searchText}>{submitText}</span>
                        </span>
                    </button>
                    <span className={errorStyle}>{error}</span>
                </div>
            </form>
        )
    }
)

InputBar.propTypes = {
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitText: PropTypes.string,
    value: PropTypes.string,
}

InputBar.defaultProps = { error: '', submitText: 'Go', value: '' }

export default InputBar
