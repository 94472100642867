import React from 'react'
import { graphql } from 'gatsby'
import GravityFormForm from 'gatsby-plugin-gravity-forms'
import addTrackingEvent from 'Utils/addTrackingEvent'
import Container from 'Containers/Container'

import './style.scss'

const Form = ({ form, sectionId, trackingName }) => {
    const triggerEvent = () => {
        addTrackingEvent({
            data: {
                name: trackingName,
            },
            event: 'Form',
        })
    }

    return (
        <Container>
            <div className="contact-form" id={sectionId}>
                <GravityFormForm data={form} successCallback={triggerEvent} />
            </div>
        </Container>
    )
}

export default Form

export const PageAcfForm = graphql`
    fragment PageAcfForm on WpPage_AcfFlexibleFields_FlexibleFields_Form {
        fieldGroupName
        sectionId
        form {
            ...GravityFormFields
        }
        trackingName
    }
`
