import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import {
    button,
    buttonContent,
    childrenStyle,
    arrow,
} from './style.module.scss'

const Button = ({ to, children }) => (
    <Link className={button} to={to}>
        <div className={buttonContent} tabIndex="-1">
            <span className={childrenStyle}>{children}</span>
        </div>
    </Link>
)

Button.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
}

export default Button
