import React from 'react'
import Container from 'Containers/Container'
import Layout from 'Containers/Layout'

const NotFoundPage = () => (
    <Layout>
        <Container>
            <h1>Couldn&#39;t Find That Page!</h1>
            <p>
                You just hit a page that doesn&#39;t exist... the sadness. It
                might be here sometime in the future? It might have been here
                sometime in the past. Unfortunatly it is not here right now :(
            </p>
        </Container>
    </Layout>
)

export default NotFoundPage
