import React from 'react'
import Wysiwyg from 'Components/Wysiwyg'
import Container from 'Containers/Container'
import SectionSpacing from 'Containers/SectionSpacing'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const AcfWysiwyg = ({ content, sectionId, isFirst }) => (
    <SectionSpacing id={sectionId} isFirst={isFirst}>
        <Container>
            <Wysiwyg>{content}</Wysiwyg>
        </Container>
    </SectionSpacing>
)

AcfWysiwyg.propTypes = {
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    sectionId: PropTypes.string,
}

AcfWysiwyg.defaultProps = {
    content: null,
    sectionId: '',
}

export default AcfWysiwyg

export const PageAcfWysiwyg = graphql`
    fragment PageAcfWysiwyg on WpPage_AcfFlexibleFields_FlexibleFields_Wysiwyg {
        sectionId
        content
    }
`
