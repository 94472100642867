import React from 'react'
import AcfBuilder from 'Components/AcfBuilder'
import SEO from 'Components/SEO'
import Layout from 'Containers/Layout'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

const PageTemplate = (props) => {
    const {
        data: { wpPage },
        uri,
    } = props

    const seoImage = getSrc(wpPage?.featuredImage?.node?.socialImage)

    const { flexibleFields } = wpPage.acf_flexible_fields

    return (
        <Layout>
            <SEO
                dateModified={wpPage.modified}
                datePublished={wpPage.date}
                description={wpPage.cleanSeoDesc}
                facebookPostImage={seoImage}
                postType="page"
                title={wpPage.cleanTitle}
                twitterPostImage={seoImage}
                url={uri}
                yoastTitle={wpPage.seo.title}
            />
            <AcfBuilder data={flexibleFields} />
        </Layout>
    )
}

export const pageQuery = graphql`
    query page($id: Int!) {
        wpPage(databaseId: { eq: $id }) {
            title
            date
            modified
            cleanTitle
            cleanSeoDesc
            content
            acf_flexible_fields {
                flexibleFields {
                    __typename
                    ...PageAcfCardPanel
                    ...PageAcfForm
                    ...PageAcfHeroBanner
                    ...PageAcfImageContentSquare
                    ...PageAcfWysiwyg
                }
            }
            featuredImage {
                node {
                    ...SocialImage
                }
            }
            seo {
                ...SEO
            }
        }
    }
`

export default PageTemplate
