import React, { useEffect, useState } from 'react'

const useScrollListener = () => {
    const [scrolled, setScrolled] = useState(false)

    const setScroll = () => {
        if (!scrolled) {
            setScrolled(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', setScroll)
        return () => {
            window.removeEventListener('scroll', setScroll)
        }
    }, [])

    return scrolled
}

export default useScrollListener
