import React from 'react'

import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Card from 'Components/Card'
import Pagination from 'Components/Pagination'
import SEO from 'Components/SEO'
import CardItem from 'Containers/CardItem'
import CardList from 'Containers/CardList'
import Container from 'Containers/Container'
import Layout from 'Containers/Layout'
import slugify from 'Utils/slugify'

const ArticlesTemplate = (props) => {
    const {
        data: {
            allWpArticle: { nodes, pageInfo },
        },
    } = props

    const postTypeUri = '/running-articles/'

    const currentPageEnd =
        pageInfo.currentPage === 1 ? '' : `page/${pageInfo.currentPage}`

    return (
        <Layout>
            <SEO
                description="Running articles, stories, tips, guides from across Leeds"
                facebookPostImage=""
                postType="article"
                title={`Articles - Page ${pageInfo.currentPage}`}
                twitterPostImage=""
                url={`${postTypeUri}${currentPageEnd}`}
                yoastTitle={`Articles - Page ${pageInfo.currentPage} | Leeds Run Routes`}
            />

            <Container listTop>
                <h1>Articles</h1>
                <CardList>
                    {nodes.map((card, count) => {
                        // Count to see if the last card is in the middle.
                        // If it is, we need to remove its spacing to stop the
                        // weird flex issue.
                        const removeLastCardFlexSpacing = (count + 1) % 3 === 2

                        return (
                            <CardItem
                                key={`${postTypeUri}${card.slug}`}
                                pullLeft={removeLastCardFlexSpacing}
                            >
                                <Card
                                    excerpt={card.cleanExcerpt}
                                    headingLevel="h2"
                                    image={getImage(
                                        card.featuredImage?.node?.localFile
                                    )}
                                    imageAlt={card.featuredImage?.node?.altText}
                                    imageTitle={card.featuredImage?.node?.title}
                                    title={card.title}
                                    uri={`${postTypeUri}${card.slug}`}
                                />
                            </CardItem>
                        )
                    })}
                </CardList>

                <Pagination
                    currentPage={pageInfo.currentPage}
                    numPages={pageInfo.pageCount}
                    prefix="/running-articles/"
                />
            </Container>
        </Layout>
    )
}

export const articlesQuery = graphql`
    query Articles($offset: Int!, $perPage: Int!) {
        allWpArticle(
            limit: $perPage
            skip: $offset
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                title
                cleanTitle
                content
                slug
                excerpt
                cleanExcerpt
                featuredImage {
                    node {
                        altText
                        title
                        ...CardImage
                    }
                }
                categories {
                    nodes {
                        slug
                        name
                    }
                }
                tags {
                    nodes {
                        slug
                        name
                    }
                }
                seo {
                    ...SEO
                }
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
    }
`

export default ArticlesTemplate
