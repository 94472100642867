import React from 'react'

import { wrap, padTop } from './style.module.scss'

const SectionSpacing = ({ id, children, isFirst }) => (
    <div id={id} className={`${wrap} ${isFirst ? padTop : ''}`}>
        {children}
    </div>
)

export default SectionSpacing
