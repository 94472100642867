import { useEffect, useState, useRef } from 'react'
import { closestLocation, getIndex } from 'Api/getLocations'
import setIntoArray from 'Utils/setIntoArray'

/**
 * A hook to hold the locations API call.
 * @param  {object} userLocation {lat,lon} for the user location.
 * @param  {string} postType The post type to get locations for.
 * @return {object} {loading, places}
 */
const useListingSorting = ({ filter, postType }) => {
    const postsLoaded = useRef(null)
    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([])
    let filterPosts = []

    useEffect(() => {
        const preFetchLocationDistances = async () => {
            // If route and needs to sort locations.
            if (postType === 'Route' && filter?.lat && filter?.lng) {
                setLoading(true)
                const result = await closestLocation(postType, filter)
                setPosts(result)
            } else {
                // Otherwise just standard normal post getter
                if (!postsLoaded.current) {
                    setLoading(true)
                    postsLoaded.current = true
                    const result = await getIndex(postType)

                    setPosts(result)
                    setLoading(false)
                }
            }
            setLoading(false)
        }

        preFetchLocationDistances()
    }, [filter?.lat, filter?.lng, postType])

    if (posts.length > 0) {
        filterPosts = [...posts]
        const filterKeys = Object.keys(filter)

        filterPosts = filterPosts?.filter((post) => {
            // All items within the filter URL are to be matched.
            // If a post is missing out on 1 filter type it is NOT shown.
            return filterKeys.every((key) => {
                if (key === 'routeDistance') {
                    // Sort route distance.
                    if (post?.len) {
                        // If the route length is smaller than the smallest selected
                        // or longer than the largest selected
                        // return false
                        if (
                            post.len < parseInt(filter.routeDistance[0]) ||
                            post.len > parseInt(filter.routeDistance[1])
                        ) {
                            return false
                        }
                    }
                }

                if (key === 'distanceFrom') {
                    // Sort route distance.
                    if (post?.dist) {
                        // If the route length is smaller than the smallest selected
                        // or longer than the largest selected
                        // return false
                        if (
                            post.dist < parseInt(filter.distanceFrom[0]) ||
                            post.dist > parseInt(filter.distanceFrom[1])
                        ) {
                            return false
                        }
                    } else {
                        // Doesnt have distance - so remove from filter.
                        return false
                    }
                }

                if (key === 'routeElevation') {
                    // Sort route distance.
                    if (post?.ele) {
                        if (
                            post.ele < parseInt(filter.routeElevation[0]) ||
                            post.ele > parseInt(filter.routeElevation[1])
                        ) {
                            return false
                        }
                    } else {
                        // Doesnt have elevation - so remove from filter.
                        return false
                    }
                }

                // If the post has this key (type/amenity/org etc).
                if (post?.[key]) {
                    // Loop through the items in this particular filter key
                    // i.e. trail, road, canal within the route type.
                    // And the post must include every item to be shown.
                    const showPost = setIntoArray(filter[key]).every((item) =>
                        post[key].includes(item)
                    )

                    if (!showPost) {
                        return false
                    }
                }

                return true
            })

            // Loop through filter keys
            // If these keys exist in post object
            // we can sort.
        })
    }

    // Return state.
    return { loading, posts, filterPosts }
}

export default useListingSorting
