import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import useScrollListener from 'Hooks/useScrollListener'
import hasWindow from 'Utils/hasWindow'
import GhostMap from './GhostMap'
import { wrap, inner } from './style.module.scss'

const MapInternals = loadable(() => import('./MapInternals'))

const MapSingle = (props) => {
    const hasScrolled = useScrollListener()

    return (
        <div className={wrap}>
            <div className={inner}>
                {hasWindow && hasScrolled && <MapInternals {...props} />}
                <GhostMap image={props.ghostImage} />
            </div>
        </div>
    )
}

export default MapSingle
