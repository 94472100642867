const dynamicSort = (property) => {
    let sortOrder = 1
    let newProperty = property
    if (newProperty[0] === '-') {
        sortOrder = -1
        newProperty = property.substr(1)
    }
    return (a, b) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result

        if (a[newProperty] < b[newProperty]) {
            result = -1
        } else if (a[newProperty] > b[newProperty]) {
            result = 1
        } else {
            result = 0
        }

        return result * sortOrder
    }
}

export default dynamicSort
