import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import GravityFormForm from 'gatsby-plugin-gravity-forms'
import {
    container,
    imageStyle,
    headingStyle,
    contentStyle,
    formWrap,
} from './style.module.scss'
import addTrackingEvent from 'Utils/addTrackingEvent'

import './form.scss'

const FullPageImageContentWrap = ({
    children,
    image,
    form,
    heading,
    description,
}) => {
    const triggerEvent = () => {
        addTrackingEvent({
            data: {
                name: 'Newsletter - Signup Page',
            },
            event: 'Form',
        })
    }

    return (
        <>
            {image && (
                <GatsbyImage
                    alt=""
                    className={imageStyle}
                    image={image}
                    loading="eager"
                    title=""
                    style={{ position: 'fixed' }}
                />
            )}
            <div className={container}>
                <h1
                    className={
                        headingStyle
                    } /* eslint-disable react/no-danger */
                    dangerouslySetInnerHTML={{ __html: heading }}
                />
                <p
                    className={contentStyle}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <div id="newsletter">
                    <GravityFormForm
                        data={form}
                        successCallback={triggerEvent}
                    />
                </div>
            </div>
        </>
    )
}

export default FullPageImageContentWrap
