import React from 'react'
import Button from 'Acf/Button'
import Container from 'Containers/Container'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as style from './style.module.scss'

const HeroBanner = ({ backImage, button, paragraph, heading }) => {
    const data = useStaticQuery(graphql`
        query Hero {
            file(relativePath: { eq: "hero.jpg" }) {
                id
                root
                relativePath
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
        }
    `)

    return (
        <div className={style.wrap}>
            <GatsbyImage
                alt=""
                image={getImage(data.file)}
                loading="eager"
                className={style.imageStyle}
                style={{ position: 'absolute' }}
            />
            <div className={style.contentWrap}>
                <Container>
                    <div className={style.layout}>
                        <div className={style.content}>
                            <h1
                                className={style.headingStyle}
                                /* eslint-disable react/no-danger */
                                dangerouslySetInnerHTML={{ __html: heading }}
                            />
                            <p className={style.paragraph}>{paragraph}</p>

                            <Button
                                customLink={button?.customLink}
                                customUrlSet={button?.customUrlSet}
                                link={button?.link}
                            >
                                {button?.text}
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

HeroBanner.propTypes = {
    button: PropTypes.shape({
        customLink: PropTypes.string,
        customUrlSet: PropTypes.bool,
    }),
    heading: PropTypes.string,
    paragraph: PropTypes.string,
}

HeroBanner.defaultProps = {
    button: {},
    heading: '',
    paragraph: '',
}

export default HeroBanner

export const PageAcfHeroBanner = graphql`
    fragment PageAcfHeroBanner on WpPage_AcfFlexibleFields_FlexibleFields_HeroBanner {
        sectionId
        heading
        button {
            customLink
            customUrlSet
            fieldGroupName
            link {
                ... on WpArticle {
                    uri
                }
                ... on WpPage {
                    uri
                }
                ... on WpRoute {
                    uri
                }
            }
            text
        }
        subheading
        button {
            customLink
            customUrlSet
            link {
                ... on WpArticle {
                    uri
                }
                ... on WpPage {
                    uri
                }
                ... on WpRoute {
                    uri
                }
            }
            text
        }
    }
`
