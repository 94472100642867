import React from 'react'
import Link from 'Atoms/Link'
import { aStyle, pStyle } from './style.module.scss'

const LocationLinks = ({ locations }) => {

    const locationsToShow = locations?.nodes?.filter((location) => location?.count > 5)

    if(locationsToShow?.length > 0) {
        const routeLinks = locationsToShow.map(({slug, name, id}, index) => (
        <>
            <Link
            className={aStyle}
            key={id}
                to={`/running-routes/locations/${slug}/`}
            >
                {name}
            </Link>
            {index < locationsToShow.length - 1 ? ", " : "."}
        </>))

        return (
            <>
                <p className={pStyle}>If you like running in this area, why not take a look at some other routes here. Running routes in {routeLinks}</p>
            </>
        )
    }

    return null
}

export default LocationLinks
