import React from 'react'
import Link from 'Atoms/Link'
import { item, itemList, itemName } from './style.module.scss'

const TagList = ({ tags }) => {
    if (tags && tags.length > 0) {
        const allTags = tags.map(({ slug, name }) => (
            <li key={slug} className={item}>
                <Link
                    to={`/running-articles/tag/${slug}/`}
                    className={itemName}
                >
                    {name}
                </Link>
            </li>
        ))

        return <ul className={itemList}>{allTags}</ul>
    }
    // No Tags
    return null
}

export default TagList
