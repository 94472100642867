import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { map, inner, gradient, ghostImage } from './style.module.scss'

const GhostMap = ({ image }) => (
    <div className={map}>
        <div className={inner}>
            <GatsbyImage
                alt="Map loading image"
                className={ghostImage}
                image={image}
                loading="eager"
            />
            <div className={gradient} />
        </div>
    </div>
)

export default GhostMap
