import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { imageStyle, imageBlur, wrap, overlay } from './style.module.scss'

const PurpleCurveImage = ({ alt, blur, image, title }) => {
    const imageStyleBuild = [imageStyle]
    if (blur) {
        imageStyleBuild.push(imageBlur)
    }

    return (
        <div className={wrap}>
            {image && (
                <GatsbyImage
                    alt={alt}
                    className={imageStyleBuild.join(' ')}
                    image={image}
                    loading="eager"
                    style={{ position: 'absolute' }}
                    title={title}
                />
            )}
            <div className={overlay} />
        </div>
    )
}

PurpleCurveImage.propTypes = {
    alt: PropTypes.string,
    blur: PropTypes.bool,
    image: PropTypes.shape({ src: PropTypes.string }),
    title: PropTypes.string,
}

PurpleCurveImage.defaultProps = {
    alt: '',
    blur: false,
    image: null,
    title: '',
}

export default PurpleCurveImage
