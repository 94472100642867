import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc, withArtDirection } from 'gatsby-plugin-image'
import SEO from 'Components/SEO'
import FullPageImageContentWrap from 'Components/FullPageImageContentWrap'
import Layout from 'Containers/Layout'

const NewsletterSignup = ({ uri }) => {
    const data = useStaticQuery(graphql`
        query NewsletterImages {
            large: file(relativePath: { eq: "newsletter-large.jpg" }) {
                id
                root
                relativePath
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
            mobile: file(relativePath: { eq: "newsletter-mob.jpg" }) {
                id
                root
                relativePath
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
            facebook: file(relativePath: { eq: "newsletter-large.jpg" }) {
                id
                root
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 1200, height: 630)
                }
            }
            wpGfForm(databaseId: { eq: 2 }) {
                ...GravityFormFields
            }
        }
    `)

    const images = withArtDirection(getImage(data.large), [
        {
            media: '(max-width: 1024px)',
            image: getImage(data.mobile),
        },
    ])

    const facebookImage = getSrc(data.facebook)

    return (
        <Layout reduced>
            <SEO
                description="Get new routes and running articles to your inbox every month. The best place to get running route inspiration in Leeds."
                facebookPostImage={facebookImage}
                title="New Run Routes to Your Inbox Every Month"
                twitterPostImage={facebookImage}
                url={uri}
            />
            <FullPageImageContentWrap
                image={images}
                form={data?.wpGfForm}
                heading="Best running route inspiration in Leeds."
                description="Trail, Road, Canal - it’s time to discover running routes near you!<br/><br/>Get new routes and running articles to your inbox every month."
            />
        </Layout>
    )
}

export default NewsletterSignup
