import haversine from 'haversine'
import dynamicSort from '../utils/dynamicSort'

let locations
let currentPostType

// Add doc block
export const getIndex = async (postType) => {
    try {
        // Get JSON file from server.
        const response = await fetch(`/${postType}Index.json`).then((r) =>
            r.json()
        )
        locations = response
    } catch (error) {
        // Manage error
        //
        // Set to empty array
        locations = []
    }
    return locations
}

// Add doc block
export const closestLocation = async (postType, user) => {
    const locationWithDistance = []

    // If no global locations provided, and the post type has
    // not yet been searched - get location.
    if (!locations || currentPostType !== postType) {
        await getIndex(postType)
        currentPostType = postType
    }

    // Loop through all locations and order by distance from user
    // provided lon/lat.
    locations.forEach((place) => {
        const distance = haversine(
            { latitude: place.loc.lat, longitude: place.loc.lng },
            { latitude: user.lat, longitude: user.lng },
            { unit: 'mile' }
        ).toFixed(1)
        const newPlace = { ...place, dist: parseFloat(distance) }
        locationWithDistance.push(newPlace)
    })

    return locationWithDistance.sort(dynamicSort('dist'))
}
