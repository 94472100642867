import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PurpleCurveImage from 'Components/PurpleCurveImage'
import Container from 'Containers/Container'
import PropTypes from 'prop-types'
import { getImage, getSrc } from 'gatsby-plugin-image'
import {
    contentWrap,
    hero,
    layout,
    content,
    headingStyle,
    imageStyle,
    textButton,
} from './style.module.scss'

const HeroBanner = ({ backImage, alt, heading }) => (
    <div className={hero}>
        <GatsbyImage
            alt={alt}
            image={backImage}
            loading="eager"
            className={imageStyle}
            style={{ position: 'absolute' }}
        />
        <div className={contentWrap}>
            <Container>
                <div className={layout}>
                    <div className={content}>
                        <h1 className={headingStyle}>{heading}</h1>
                    </div>
                </div>
            </Container>
        </div>
    </div>
)

HeroBanner.propTypes = {
    alt: PropTypes.string,
    backImage: PropTypes.shape({ src: PropTypes.string }),
    heading: PropTypes.string,
}

HeroBanner.defaultProps = {
    alt: '',
    backImage: false,
    heading: '',
}

export default HeroBanner
