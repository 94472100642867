import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image'
import { wrap, imageStyle } from './style.module.scss'

const RouteImage = ({ image, alt }) => {
    const gatsbyImageArgs = getImage(image)

    return (
        <GatsbyImage
            alt={alt}
            image={gatsbyImageArgs}
            loading="eager"
            className={imageStyle}
        />
    )
}

RouteImage.propTypes = {
    alt: PropTypes.string,
    image: PropTypes.shape({ src: PropTypes.string }),
}

RouteImage.defaultProps = {
    alt: '',
    image: false,
}

export default RouteImage
