import React from 'react'
import { card, cardInner, gradient } from './style.module.scss'

const GhostCard = () => (
    <div className={card}>
        <div className={cardInner}>
            <div className={gradient} />
        </div>
    </div>
)

export default GhostCard
