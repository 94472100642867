import React, { useRef, useState } from 'react'
import Card from 'Components/Card'
import GhostCard from 'Components/GhostCard'
import Filter from 'Components/Filter'
import Pagination from 'Components/Pagination'
import SlimSearch from 'Components/SlimSearch'
import SEO from 'Components/SEO'
import CardItem from 'Containers/CardItem'
import CardList from 'Containers/CardList'
import Container from 'Containers/Container'
import Layout from 'Containers/Layout'
import useListingSorting from 'Hooks/useListingSorting'
import { useLocation } from '@reach/router'
import cleanDistance from 'Utils/cleanDistance'
import cleanElevation from 'Utils/cleanElevation'
import dynamicSort from 'Utils/dynamicSort'
import getPagesChunk from 'Utils/getPagesChunk'
import slugify from 'Utils/slugify'
import isObjectEmpty from 'Utils/isObjectEmpty'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import queryString from 'query-string'

const RoutesTemplate = (props) => {
    const {
        data: {
            allWpRoute: { nodes, pageInfo },
            hero: {
                options: {
                    acfOptions: { routeHero },
                },
            },
            filterType,
            filterAmenity,
            filterOrganiser,
            filterRouteDistance,
            filterRouteElevation,
        },
    } = props

    const scrollPointRef = useRef(null)
    const [filterOpen, setFilterOpen] = useState(false)

    let filteredPostPageCount = 0
    let chunkedPosts = []

    const rootUri = '/running-routes/'

    const location = useLocation()
    const filterArgs =
        queryString.parse(location.search, { arrayFormat: 'comma' }) || {}

    let { loading, posts, filterPosts } = useListingSorting({
        postType: 'Route',
        filter: filterArgs,
    })

    const hasFilters = !isObjectEmpty(filterArgs)

    if (hasFilters) {
        chunkedPosts = getPagesChunk(filterPosts, filterArgs?.page || 1) || []
    }

    filteredPostPageCount = filterArgs ? Math.ceil(filterPosts.length / 12) : 0

    const filteredArgsNoPage = { ...filterArgs }
    delete filteredArgsNoPage.page

    const routeDistanceFrom = posts
        .map((item) => item?.dist)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort(dynamicSort('dist'))

    let filterRouteDistanceFromClean = [...routeDistanceFrom]

    filterRouteDistanceFromClean = {
        min: Math.floor(filterRouteDistanceFromClean[0]),
        max: Math.round(filterRouteDistanceFromClean.pop()),
    }

    const filterDistances = [
        {
            name: 'Route Distance',
            slug: 'routeDistance',
            description: 'Filter by the distance of the route.',
            values: {
                max: cleanDistance(filterRouteDistance.max),
                min: cleanDistance(filterRouteDistance.min),
            },
        },
        {
            name: 'Distance From',
            slug: 'distanceFrom',
            description: 'Filter by how close the route is to your location.',
            values: filterRouteDistanceFromClean,
        },
    ]

    const filterElevations = [
        {
            name: 'Route Elevation',
            slug: 'routeElevation',
            description: 'Filter by the elevation gain of a route.',
            values: {
                max: cleanElevation(filterRouteElevation.max),
                min: cleanElevation(filterRouteElevation.min),
            },
            unit: 'm',
        },
    ]

    const currentPageEnd =
        pageInfo.currentPage === 1 ? '' : `page/${pageInfo.currentPage}`

    const seoImage = getSrc(routeHero?.backImage?.socialImage)

    const seoTitle = `Routes${
        pageInfo.currentPage > 1 ? ' - Page ' + pageInfo.currentPage : ''
    }`

    return (
        <Layout>
            <SEO
                description="Discover running routes near you! Filter by route type (trail, woods etc), route distance, and if there is nearby parking."
                facebookPostImage={seoImage}
                postType="page"
                title={seoTitle}
                twitterPostImage={seoImage}
                url={`${rootUri}${currentPageEnd}`}
            />

            <SlimSearch
                scrollPointRef={scrollPointRef}
                rootUri={rootUri}
                searchButton="Go"
                searchId="search-route"
                searchLabel="Enter Postcode"
            />

            <Container space>
                <div ref={scrollPointRef} />

                <Filter
                    open={filterOpen}
                    setOpen={setFilterOpen}
                    pageUri="running-routes"
                    filters={[
                        {
                            name: 'Type',
                            slug: 'type',
                            items: filterType.nodes,
                            type: 'check',
                        },
                        {
                            slug: 'distance',
                            name: 'Distance',
                            items: filterDistances,
                            type: 'select',
                            multiple: true,
                        },
                        {
                            slug: 'ele',
                            name: 'Elevation',
                            items: filterElevations,
                            type: 'select',
                            multiple: true,
                        },
                        {
                            name: 'Amenities',
                            slug: 'amn',
                            items: filterAmenity.nodes,
                            type: 'check',
                        },
                        {
                            name: 'Organiser',
                            slug: 'org',
                            items: filterOrganiser.nodes,
                            type: 'check',
                        },
                    ]}
                />

                {hasFilters && loading && (
                    <CardList>
                        {[...Array(12)].map((x, i) => {
                            // Count to see if the last card is in the middle.
                            // If it is, we need to remove its spacing to stop the
                            // weird flex issue.
                            const removeLastCardFlexSpacing = (i + 1) % 3 === 2

                            return (
                                <CardItem
                                    key={slugify(`GhostCard-${i}`)}
                                    pullLeft={removeLastCardFlexSpacing}
                                >
                                    <GhostCard />
                                </CardItem>
                            )
                        })}
                    </CardList>
                )}

                {hasFilters && !loading && filteredPostPageCount > 0 && (
                    <>
                        <CardList>
                            {chunkedPosts.map((card, count) => {
                                // Count to see if the last card is in the middle.
                                // If it is, we need to remove its spacing to stop the
                                // weird flex issue.
                                const removeLastCardFlexSpacing =
                                    (count + 1) % 3 === 2

                                return (
                                    <CardItem
                                        key={slugify(card.slu)}
                                        pullLeft={removeLastCardFlexSpacing}
                                    >
                                        <Card
                                            cats={card.typeN}
                                            distance={card.dist}
                                            excerpt={card.exc}
                                            headingLevel="h2"
                                            image={card.img.img}
                                            imageAlt={card.img.alt}
                                            imageTitle={card.img.tit}
                                            routeLength={card.len}
                                            routeElev={card.ele}
                                            title={card.tit}
                                            uri={card.slu}
                                        />
                                    </CardItem>
                                )
                            })}
                        </CardList>
                        <Pagination
                            currentPage={parseInt(filterArgs?.page, 10) || 1}
                            numPages={filteredPostPageCount}
                            prefix="/running-routes/"
                            type="query"
                        />
                    </>
                )}

                {!hasFilters && nodes.length > 0 && (
                    <>
                        <CardList>
                            {nodes.map((card, count) => {
                                // Count to see if the last card is in the middle.
                                // If it is, we need to remove its spacing to stop the
                                // weird flex issue.
                                const removeLastCardFlexSpacing =
                                    (count + 1) % 3 === 2

                                const typeName =
                                    card.routeTypes?.nodes.map(
                                        ({ name }) => name
                                    ) || []

                                return (
                                    <CardItem
                                        key={slugify(card.uri)}
                                        pullLeft={removeLastCardFlexSpacing}
                                    >
                                        <Card
                                            excerpt={card.cleanExcerpt}
                                            headingLevel="h2"
                                            image={getImage(
                                                card.acf_routes.generatedCard
                                                    ?.localFile
                                            )}
                                            cats={typeName}
                                            imageAlt={`${card.title} run route map card image`}
                                            imageTitle={`${card.title} run route map card image`}
                                            routeLength={card.cleanDistance}
                                            routeElev={card.cleanElevation}
                                            title={card.title}
                                            uri={card.uri}
                                        />
                                    </CardItem>
                                )
                            })}
                        </CardList>
                        <Pagination
                            currentPage={parseInt(pageInfo.currentPage, 10)}
                            numPages={pageInfo.pageCount}
                            prefix="/running-routes/"
                        />
                    </>
                )}

                {(!loading && !hasFilters && nodes.length === 0) ||
                    (!loading && hasFilters && chunkedPosts.length === 0 && (
                        <p>
                            Unfortunately there is nothing for that combination!
                        </p>
                    ))}
            </Container>
        </Layout>
    )
}

export const routeListingQuery = graphql`
    query routes($offset: Int!, $perPage: Int!) {
        allWpRoute(
            limit: $perPage
            skip: $offset
            sort: { fields: title, order: ASC }
        ) {
            nodes {
                title
                cleanTitle
                uri
                excerpt
                content
                cleanExcerpt
                routeDistance
                routeTotalElev
                routeTypes {
                    nodes {
                        name
                    }
                }
                cleanDistance
                cleanElevation
                acf_routes {
                    generatedCard {
                        ...RouteCardImage
                    }
                }
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
        hero: wp {
            options {
                acfOptions {
                    routeHero {
                        backImage {
                            ...SocialImage
                        }
                    }
                }
            }
        }
        filterType: allWpRouteType(filter: { count: { gt: 0 } }) {
            nodes {
                slug
                name
            }
        }
        filterAmenity: allWpAmenity(filter: { count: { gt: 0 } }) {
            nodes {
                slug
                name
            }
        }
        filterOrganiser: allWpRouteOrganiser(filter: { count: { gt: 0 } }) {
            nodes {
                slug
                name
            }
        }
        filterRouteDistance: allWpRoute {
            max(field: routeDistance)
            min(field: routeDistance)
        }
        filterRouteElevation: allWpRoute {
            max(field: routeTotalElev)
            min(field: routeTotalElev)
        }
    }
`

export default RoutesTemplate
