import React from 'react'
import RootCardPanel from 'Components/CardPanel'
import { graphql } from 'gatsby'

const CardPanel = ({ button, content, cards, heading, sectionId }) => (
    <RootCardPanel
        id={sectionId}
        cards={cards}
        content={content}
        heading={heading}
        button={button}
    />
)

export default CardPanel

export const PageAcfCardPanel = graphql`
    fragment PageAcfCardPanel on WpPage_AcfFlexibleFields_FlexibleFields_CardPanel {
        sectionId
        heading
        content
        button {
            customLink
            customUrlSet
            fieldGroupName
            link {
                ... on WpArticle {
                    uri
                }
                ... on WpPage {
                    uri
                }
                ... on WpRoute {
                    uri
                }
            }
            text
        }
        cards {
            ... on WpArticle {
                nodeType
                slug
                title
                cleanTitle
                excerpt
                content
                cleanExcerpt
                featuredImage {
                    node {
                        altText
                        title
                        ...CardImage
                    }
                }
            }
            ... on WpRoute {
                nodeType
                title
                cleanTitle
                slug
                excerpt
                content
                cleanExcerpt
                routeTotalElev
                routeDistance
                routeTypes {
                    nodes {
                        name
                    }
                }
                cleanDistance
                cleanElevation
                acf_routes {
                    generatedCard {
                        altText
                        title
                        ...RouteCardImage
                    }
                }
            }
        }
    }
`
