import React, { useEffect, useState } from 'react'
import Title from 'Atoms/Title'
import AdBlock from 'Components/AdBlock'
import ElevationChart from 'Components/ElevationChart'
// import FullWidthCarousel from 'Components/FullWidthCarousel'
// import LastModifiedDate from 'Components/LastModifiedDate'
import PlaceHero from 'Components/PlaceHero'
import QuickLinks from 'Components/QuickLinks'
import SEO from 'Components/SEO'
import CardPanel from 'Components/CardPanel'
import NewsletterSignupForm from 'Components/Shortcodes/NewsletterSignupForm'
import MapSingle from 'Components/MapSingle'
import LocationLinks from 'Components/LocationLinks'
import Wysiwyg from 'Components/Wysiwyg'
import Container from 'Containers/RouteContainer'
import Layout from 'Containers/Layout'
import SectionSpacing from 'Containers/SectionSpacing'
import useRouteJson from 'Hooks/useRouteJson'
import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'

const RouteTemplate = (props) => {
    const {
        data: {
            relatedPosts,
            wpRoute: {
                date,
                cleanTitle,
                cleanExcerpt,
                cleanSeoDesc,
                modified,
                modifiedForSchema,
                featuredImage,
                amenities: amenityList,
                routeOrganisers,
                routeTypes,
                routeStart,
                routeLocations,
                cleanDistance,
                cleanElevation,
                longestDecline,
                longestIncline,
                routeBounds,
                acf_routes: {
                    routeHighlights,
                    bestTimeToRun,
                    amenities,
                    foodAndDrink,
                    locationImage,
                    location,
                    overview,
                    parking,
                    warnings,
                    theRoute,
                    routeElevJson,
                    routePolyJson,
                    generatedFacebook: { mapGhostImage, socialImage },
                },
                seo,
            },
        },
        uri,
    } = props

    const titleLinks = []

    const seoImage = getSrc(socialImage)
    const mapImage = getImage(mapGhostImage)

    // const bannerImage = getImage(featuredImage?.node?.heroBanner)
    // const bannerCardImage = getImage(cardImage?.localFile)
    // const featuredAlt = featuredImage?.node?.altText

    const breadcrumbs = [
        {
            title: 'Running Routes',
            uri: '/running-routes/',
        },
        { title: cleanTitle, uri },
    ]

    // // Create slide array
    // let exploreImageSlides = []
    // if (imageGallery) {
    //     exploreImageSlides = imageGallery.map(({ image }) => ({
    //         alt: image?.altText,
    //         image: getImage(image?.localFile),
    //         title: image?.title,
    //     }))
    // }

    if (overview) {
        titleLinks.push({
            name: 'Overview',
            slug: '#overview',
        })
    }

    if (theRoute) {
        titleLinks.push({
            name: 'Route Description',
            slug: '#route-description',
        })
    }

    if (warnings) {
        titleLinks.push({
            name: 'Warnings',
            slug: '#warnings',
        })
    }

    if (location) {
        titleLinks.push({
            name: 'Location',
            slug: '#location',
        })
    }

    if (amenities) {
        titleLinks.push({
            name: 'Amenities',
            slug: '#amenities',
        })
    }

    if (parking) {
        titleLinks.push({
            name: 'Parking',
            slug: '#parking',
        })
    }

    if (foodAndDrink) {
        titleLinks.push({
            name: 'Food and Drink',
            slug: '#food-drink',
        })
    }

    titleLinks.push({
        name: 'Nearby Running Routes',
        slug: '#nearby-running-routes',
    })

    const { loading, poly, elev } = useRouteJson({
        elevUrl: routeElevJson?.localFile?.publicURL,
        polyUrl: routePolyJson?.localFile?.publicURL,
    })

    let distanceInfo = ''
    if (cleanDistance && cleanElevation) {
        distanceInfo = `This route has a distance of ${cleanDistance}km and an elevation gain of ${cleanElevation}m.`
    }

    if (cleanDistance > 1) {
        const {
            elev: inclineElev,
            dist: inclineDist,
            positionlabel: inclinePositionlabel,
        } = JSON.parse(longestIncline)

        const {
            elev: declineElev,
            dist: declineDist,
            positionlabel: declinePositionlabel,
        } = JSON.parse(longestDecline)

        if (inclinePositionlabel) {
            distanceInfo += `<p>The largest non-stop incline begins ${inclinePositionlabel} of the route, and has a distance of ${inclineDist}m and an elevation gain of ${inclineElev}m. You can see this on the above elevation chart in red.</p>`
        }

        if (declinePositionlabel) {
            distanceInfo += `<p>The largest continual decline is ${declineDist}m long with an elevation drop of ${declineElev}m which starts ${declinePositionlabel} the route. This is shown on the chart as green.</p>`
        }
    }

    let highlightContent = ''
    if (routeHighlights) {
        highlightContent = `<h3>Route Highlights</h3><p>${routeHighlights}</p>`
    }

    let bestTimeContent = ''
    if (bestTimeToRun) {
        bestTimeContent = `<h3>Best Time To Run</h3><p>${bestTimeToRun}</p>`
    }

    let adaptedOverview =
        overview +
        `<p>Wanting to find your way to the start of the route? Here is a link for a <a href="https://www.google.com/maps/dir/${routeStart[0]},${routeStart[1]}" target="_blank" rel="noopener noreferrer">Google Maps Directions</a> page.</p><p>${distanceInfo}</p>${highlightContent}${bestTimeContent}`

    return (
        <Layout>
            <SEO
                dateModified={modified}
                datePublished={date}
                description={cleanSeoDesc}
                facebookPostImage={seoImage}
                location={location}
                postType="route"
                title={cleanTitle}
                twitterPostImage={seoImage}
                url={uri}
                yoastTitle={seo.title}
            />

            <article>
                <PlaceHero
                    paragraph={cleanExcerpt}
                    heading={cleanTitle}
                    crumbs={breadcrumbs}
                    distance={cleanDistance}
                    elevation={cleanElevation}
                    image={locationImage?.localFile}
                    cats={[
                        {
                            uri: '/running-routes/type',
                            items: routeTypes.nodes,
                        },
                        {
                            uri: '/running-routes/organisers',
                            items: routeOrganisers.nodes,
                        },
                    ]}
                />

                <Container>
                    <AdBlock />
                </Container>

                <MapSingle
                    ghostImage={mapImage}
                    route={poly}
                    bounds={routeBounds}
                />

                {!loading && elev && (
                    <Container>
                        <ElevationChart
                            elev={elev}
                            decline={longestDecline}
                            incline={longestIncline}
                        />
                    </Container>
                )}

                {
                    // {exploreImageSlides.length > 0 && (
                    //     <SectionSpacing>
                    //         <Container>
                    //             <Title heading="h2" id="take-a-look-around">
                    //                 Take a look around
                    //             </Title>
                    //         </Container>
                    //         <FullWidthCarousel slides={exploreImageSlides} />
                    //     </SectionSpacing>
                    // )}
                }

                <SectionSpacing>
                    <Container>
                        {titleLinks && <QuickLinks links={titleLinks} />}
                        {overview && (
                            <>
                                <Title heading="h2" id="overview" marginTop>
                                    Overview
                                </Title>
                                <Wysiwyg>{adaptedOverview}</Wysiwyg>
                            </>
                        )}

                        {theRoute && (
                            <>
                                <Title
                                    heading="h2"
                                    id="route-description"
                                    marginTop
                                >
                                    Route Description
                                </Title>
                                <Wysiwyg>{theRoute}</Wysiwyg>
                            </>
                        )}
                        <Container>
                            <AdBlock />
                        </Container>
                        {warnings && (
                            <>
                                <Title heading="h2" id="warnings" marginTop>
                                    Warnings
                                </Title>
                                <Wysiwyg>{warnings}</Wysiwyg>
                            </>
                        )}
                        {location && (
                            <>
                                <Title heading="h2" id="location" marginTop>
                                    Location
                                </Title>
                                <Wysiwyg>{location}</Wysiwyg>
                                <LocationLinks locations={routeLocations} />
                            </>
                        )}
                        <NewsletterSignupForm />
                        {amenities && (
                            <>
                                <Title heading="h2" id="amenities" marginTop>
                                    Amenities
                                </Title>
                                <Wysiwyg>{amenities}</Wysiwyg>
                            </>
                        )}
                        {parking && (
                            <>
                                <Title heading="h2" id="parking" marginTop>
                                    Parking
                                </Title>
                                <Wysiwyg>{parking}</Wysiwyg>
                            </>
                        )}
                        {foodAndDrink && (
                            <>
                                <Title heading="h2" id="food-drink" marginTop>
                                    Food and Drink
                                </Title>
                                <Wysiwyg>{foodAndDrink}</Wysiwyg>
                            </>
                        )}
                    </Container>
                </SectionSpacing>
            </article>
            <CardPanel
                cards={relatedPosts.nodes}
                uri="running-routes"
                content={`Looking for other routes near ${cleanTitle}? Here are three just around the corner.`}
                heading={`Other Running Routes Nearby`}
                id="nearby-running-routes"
            />
        </Layout>
    )
}

export const routeQuery = graphql`
    query route($id: Int!, $related: [Int!]) {
        wpRoute(databaseId: { eq: $id }) {
            date
            modified
            modifiedForSchema: modified(formatString: "YYYY-MM-DD, HH:mm:ss")
            title
            cleanTitle
            databaseId
            excerpt
            cleanExcerpt
            cleanSeoDesc
            routeDistance
            cleanDistance
            routeTotalElev
            cleanElevation
            longestDecline
            longestIncline
            routeBounds
            routeStart
            featuredImage {
                node {
                    altText
                    ...HeroBanner
                }
            }
            amenities {
                nodes {
                    name
                    slug
                    id
                    count
                }
            }
            routeOrganisers {
                nodes {
                    name
                    slug
                    id
                    count
                }
            }
            routeTypes {
                nodes {
                    name
                    slug
                    id
                    count
                }
            }
            routeLocations {
                nodes {
                    name
                    slug
                    id
                    count
                }
            }
            acf_routes {
                routeHighlights
                bestTimeToRun
                amenities
                foodAndDrink
                location
                overview
                parking
                warnings
                theRoute
                routeElevJson {
                    localFile {
                        publicURL
                    }
                }
                locationImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                width: 480
                                height: 530
                            )
                        }
                    }
                }
                routePolyJson {
                    localFile {
                        publicURL
                    }
                }
                generatedFacebook {
                    ...SocialImage
                    ...MapGhostImage
                }
            }
            seo {
                ...SEO
            }
        }
        relatedPosts: allWpRoute(filter: { databaseId: { in: $related } }) {
            nodes {
                title
                cleanTitle
                nodeType
                slug
                excerpt
                content
                cleanExcerpt
                routeDistance
                cleanDistance
                routeTotalElev
                cleanElevation
                routeStart
                acf_routes {
                    generatedCard {
                        ...RouteCardImage
                    }
                }
            }
        }
    }
`

export default RouteTemplate
