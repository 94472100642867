import React from 'react'

import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Card from 'Components/Card'
import Pagination from 'Components/Pagination'
import SEO from 'Components/SEO'
import Wysiwyg from 'Components/Wysiwyg'
import CardItem from 'Containers/CardItem'
import CardList from 'Containers/CardList'
import Container from 'Containers/Container'
import Layout from 'Containers/Layout'

const PostsTagPage = ({ data, pageContext }) => {
    const {
        allWpArticle: { nodes, pageInfo },
        wpTag,
    } = data

    const { currentPage } = pageInfo

    const { index, pageCount, archivePath, postTypeUri } = pageContext

    return (
        <Layout>
            <SEO
                description={wpTag.cleanSeoDesc}
                postType="catTagsList"
                title={`Article - ${wpTag.cleanTitle}`}
                url={`/${archivePath}`}
            />

            <Container listTop>
                <h1>Articles - Tag: {wpTag.cleanTitle}</h1>
                <Wysiwyg>{wpTag.description}</Wysiwyg>
                <CardList>
                    {nodes.map((card, count) => {
                        // Count to see if the last card is in the middle.
                        // If it is, we need to remove its spacing to stop the
                        // weird flex issue.
                        const removeLastCardFlexSpacing = (count + 1) % 3 === 2

                        return (
                            <CardItem
                                key={`${postTypeUri}${card.slug}`}
                                pullLeft={removeLastCardFlexSpacing}
                            >
                                <Card
                                    excerpt={card.cleanExcerpt}
                                    headingLevel="h2"
                                    image={getImage(
                                        card.featuredImage?.node?.localFile
                                    )}
                                    imageAlt={card.featuredImage?.node?.altText}
                                    imageTitle={card.featuredImage?.node?.title}
                                    title={card.title}
                                    uri={`${postTypeUri}${card.slug}`}
                                />
                            </CardItem>
                        )
                    })}
                </CardList>

                <Pagination
                    currentPage={pageInfo.currentPage}
                    numPages={pageInfo.pageCount}
                    prefix={archivePath}
                />
            </Container>
        </Layout>
    )
}

export const postsTagQuery = graphql`
    query postsTag($id: Int!, $offset: Int!, $perPage: Int!) {
        wpTag(databaseId: { eq: $id }) {
            name
            description
            cleanTitle
            cleanSeoDesc
        }
        allWpArticle(
            limit: $perPage
            skip: $offset
            sort: { fields: date, order: DESC }
            filter: {
                tags: { nodes: { elemMatch: { databaseId: { eq: $id } } } }
            }
        ) {
            nodes {
                title
                cleanTitle
                content
                slug
                excerpt
                cleanExcerpt
                featuredImage {
                    node {
                        altText
                        title
                        ...CardImage
                    }
                }
                seo {
                    ...SEO
                }
            }
            pageInfo {
                currentPage
            }
        }
    }
`

export default PostsTagPage
